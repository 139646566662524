import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShellIntl } from '@onyx/shell';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShellTranslationsService extends ShellIntl implements OnDestroy {
  public languageSubscription: Subscription;

  constructor(private _service: TranslateService) {
    super();

    this.languageSubscription = _service.onLangChange.subscribe(() =>
      this._updateLabels()
    );

    this._updateLabels();
  }

  public ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  private _updateLabels(): void {
    this.closeMenuLabel = this._service.instant('onyx.shell.closeMenuLabel');
    this.openMenuLabel = this._service.instant('onyx.shell.openMenuLabel');
    this.byLabel = this._service.instant('onyx.shell.byLabel');
    this.settingsLabel = this._service.instant('onyx.shell.settingsLabel');
    this.termsLabels = {
      contact: this._service.instant('onyx.shell.termsLabels.contact'),
      productOf: this._service.instant('onyx.shell.termsLabels.productOf'),
      rightsReserved: this._service.instant(
        'onyx.shell.termsLabels.rightsReserved'
      ),
      usage: this._service.instant('onyx.shell.termsLabels.usage'),
      termsConditions: this._service.instant(
        'onyx.shell.termsLabels.termsConditions'
      )
    };

    this.triggerChanges();
  }
}
