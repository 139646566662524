export const environment = {
  cloudApiUri: 'https://digitaltwin-api-testing.stack-suite.net/',
  redirectUri: 'https://digitaltwin-frontend-testing.stack-suite.net',
  identity: {
    clientId: 'db974ab0-1c2d-4e67-827e-52683e14a90f',
    scopes:
      'openid profile offline_access db974ab0-1c2d-4e67-827e-52683e14a90f',
    authority:
      'https://login-dev.kion.cloud/5444bcdd-4856-42a6-87d9-6fe9414347e6/B2C_1A_CXP_SigninSignup_KION/v2.0',
    authWellknownEndpointUrl:
      'https://login-dev.kion.cloud/5444bcdd-4856-42a6-87d9-6fe9414347e6/B2C_1A_CXP_SigninSignup_KION/v2.0/.well-known/openid-configuration',
    redirectUrl: 'https://digitaltwin-frontend-testing.stack-suite.net',
    securedEndpoints: [
      'https://digitaltwin-api-testing.stack-suite.net/',
      'https://localhost:7253/'
    ]
  }
};
