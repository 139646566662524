import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { RequestOverviewItem } from '../../models/request-overview-item';

@Component({
  selector: 'app-request-overview-sidepane',
  templateUrl: './request-overview-sidepane.component.html',
  styleUrl: './request-overview-sidepane.component.scss'
})
export class RequestOverviewSidepaneComponent implements OnChanges {
  public showDetails = false;

  @Input()
  public item: RequestOverviewItem | null = null;

  @Output()
  public downloadAllReceived = new EventEmitter<RequestOverviewItem>();

  @Output()
  public downloadTDF = new EventEmitter<RequestOverviewItem>();

  @Output()
  public viewTDF = new EventEmitter<RequestOverviewItem>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.showDetails = false;
    }
  }
}
