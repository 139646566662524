import { CanActivateFn, Router } from '@angular/router';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export const digitalTwinRoleGuard = (requiredRole: string): CanActivateFn => {
  return async () => {
    const digitalTwinService = inject(DigitalTwinApiService);
    const router = inject(Router);
    const response = await firstValueFrom(digitalTwinService.getUserRoles());
    if (response && response.includes(requiredRole)) {
      return true;
    } else {
      router.navigate(['/unauthorized']);
      return false;
    }
  };
};
