import { LanguageOption } from '@onyx/shell';

export const supportedLangaugeOptions: LanguageOption[] = [
  { key: 'en', label: 'English' },
  { key: 'nl', label: 'Nederlands' },
  { key: 'fr', label: 'Francais' },
  { key: 'de', label: 'German' },
  { key: 'it', label: 'Italiano' },
  { key: 'es', label: 'Espanol' }
];
