<ui-shell>
  <ng-container content>
    <router-outlet></router-outlet>
  </ng-container>
  <div sidePanel>
    <ng-content select="[sidePanel]"></ng-content>
  </div>
  <ui-shell-global-settings>
    <ui-shell-global-settings-user-preferences>
      <ui-shell-global-settings-category-screen></ui-shell-global-settings-category-screen>
      <ui-shell-global-settings-category-language
        [language]="_translateService.currentLang"
        [languages]="languageOptions"
        (languageChange)="selectLanguage($event)"
      ></ui-shell-global-settings-category-language>
    </ui-shell-global-settings-user-preferences>

    <ui-shell-global-setting-panel
      key="component-placement"
      title="Component placement"
    >
    </ui-shell-global-setting-panel>
  </ui-shell-global-settings>
  <ui-shell-profile [userInfo]="profileConfig" />
</ui-shell>
