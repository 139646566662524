<ng-container *ngIf="truckDefinition">
  <!-- components -->
  <p *ngIf="!truckDefinition?.truck_definition?.components?.length">
    {{ 'node.truckDefinition.components.none' | translate }}
  </p>
  <ng-container
    *ngIf="truckDefinition?.truck_definition?.components?.length"
  >
    <p>{{ 'node.truckDefinition.components.title' | translate }}</p>
    <table
      [dataSource]="truckDefinition!.truck_definition!.components"
      mat-table
    >
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.truckDefinition.components.name' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="partnumber">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.truckDefinition.components.partnumber' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.partNumber }}
        </td>
      </ng-container>
      <tr *matHeaderRowDef="['name', 'partnumber']" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['name', 'partnumber']" mat-row></tr>
    </table>
  </ng-container>
  <!-- properties -->
  <p *ngIf="!truckDefinition?.truck_definition?.properties?.length">
    {{ 'node.truckDefinition.properties.none' | translate }}
  </p>
  <ng-container
    *ngIf="truckDefinition?.truck_definition?.properties?.length"
  >
    <p>{{ 'node.truckDefinition.components.title' | translate }}</p>
    <table
      [dataSource]="truckDefinition!.truck_definition!.properties"
      mat-table
    >
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.truckDefinition.properties.name' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.truckDefinition.properties.value' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.value }}
        </td>
      </ng-container>
      <tr *matHeaderRowDef="['name', 'value']" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['name', 'value']" mat-row></tr>
    </table>
  </ng-container>
</ng-container>
