import { Component, Input } from '@angular/core';
import { ReportedPartOverview } from '../../models/reported-part-overview';

@Component({
  selector: 'app-node-reported-parts',
  templateUrl: './node-reported-parts.component.html'
})
export class NodeReportedPartsComponent {
  @Input() public reportedParts: ReportedPartOverview | null = null;
}
