import { Component, Input } from '@angular/core';
import { CrcCodesOverview } from '../../models/crc-codes-overview';

@Component({
  selector: 'app-node-crc-codes',
  templateUrl: './node-crc-codes.component.html'
})
export class NodeCrcCodesComponent {
  @Input() public crc: CrcCodesOverview | null = null;
}
