import { Component, Input } from '@angular/core';
import { BlobInfo } from '../../models/blob-info';

@Component({
  selector: 'app-node-blobs',
  templateUrl: './node-blobs.component.html'
})
export class NodeBlobsComponent {
  @Input() public blobs: BlobInfo[] | null = null;
}
