import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/config/app.config';

// https://timdeschryver.dev/blog/angular-build-once-deploy-to-multiple-environments
fetch('/digital-twin/assets/config/config.json')
  .then(response => response.json())
  .then(config => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
