import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-unauthorized-page',
  standalone: false,
  templateUrl: './unauthorized-page.component.html',
  styleUrl: './unauthorized-page.component.scss'
})
export class UnauthorizedPageComponent {
  public userId: string = '';
  constructor(public authService: OidcSecurityService) {
    this.authService.getUserData().subscribe(data => {
      this.userId = data.uid;
    });
  }
}
