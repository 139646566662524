import { Component, Input } from '@angular/core';
import { DigitalTwinNodeHistory } from '../../models/digital-twin-node-history';

@Component({
  selector: 'app-node-history',
  templateUrl: './node-history.component.html'
})
export class NodeHistoryComponent {
  @Input() public history: DigitalTwinNodeHistory[] | null = null;
}
