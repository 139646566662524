import { Component } from '@angular/core';
import {
  Application,
  Brand,
  CustomAction,
  ThemeAndBrandService
} from '@onyx/core';
import {
  NavigationService,
  ResponsiveService,
  UserProfileInfo
} from '@onyx/shell';
import { Observable, map } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { supportedLangaugeOptions } from './shared/modules/translations/supportedLanguages';
import { TranslatedNavigationService } from './shared/modules/translations/onyx/translated-navigation.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

marker([
  'enums.nodeTypeEnum.Template',
  'enums.nodeTypeEnum.Vehicle',
  'enums.nodeTypeEnum.Group'
]);

marker([
  'enums.setFileUpdateOptions.UseSource',
  'enums.setFileUpdateOptions.Clear',
  'enums.setFileUpdateOptions.Leave'
]);

marker(['nav.home', 'nav.requestOverview']);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public title = 'Digital Twin Manager';
  public isMobile$: Observable<boolean>;
  public profileConfig: UserProfileInfo = {
    email: '',
    firstName: '',
    lastName: '',
    logoutAction: new CustomAction(() => this._authService.logoff().subscribe())
  };
  public languageOptions = supportedLangaugeOptions;

  constructor(
    private _stackTheme: ThemeAndBrandService,
    responsiveService: ResponsiveService,
    public _authService: OidcSecurityService,
    public _translateService: TranslateService,
    private _navigationService: NavigationService,
    private _translatedNavigationService: TranslatedNavigationService
  ) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(flags => flags.mobile)
    );
    this._stackTheme.changeBrand(Brand.Kion);
    this._stackTheme.changeApplication(Application.Orchestrator);

    this._translateService.onLangChange.subscribe(() => {
      this._navigationService.update(
        this._translatedNavigationService.getTranslatedNavigationConfig()
      );
    });
  }

  public selectLanguage(languageKey: string): void {
    localStorage.setItem('userSelectedLanguage', languageKey);
    this._translateService.use(languageKey);
  }
}
