import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { DigitalTwinNode } from '../models/digital-twin-node';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { DigitalTwinNodeRelationsEnum } from '../models/digital-twin-node-relations.enum';
import { DigitalTwinNodeTypeEnum } from '../models/digital-twin-node-type.enum';
import { DigitalTwinGetContentResponse } from '../models/digital-twin-get-content-response';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html'
})
export class NodeComponent implements OnDestroy {
  public _node$: Observable<DigitalTwinNode>;
  public _destroy$ = new Subject<void>();
  public _nodeTypeEnum = DigitalTwinNodeTypeEnum;

  public _content$: Observable<DigitalTwinGetContentResponse | null>;

  constructor(
    activatedRoute: ActivatedRoute,
    private _router: Router,
    private _service: DigitalTwinApiService,
    private _dialog: MatDialog,
    private _translation: TranslateService
  ) {
    this._node$ = activatedRoute.paramMap.pipe(
      switchMap(params => {
        return this._service.getNodeById(
          parseInt(params.get('id')!, 10),
          DigitalTwinNodeRelationsEnum.ParentNode |
            DigitalTwinNodeRelationsEnum.ChildNodes
        );
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this._content$ = this._node$.pipe(
      mergeMap(node => {
        return node.nodeType === DigitalTwinNodeTypeEnum.Vehicle
          ? this._service.getNodeContentById(node.id)
          : of(null);
      }),
      takeUntil(this._destroy$)
    );
  }

  public delete(node: DigitalTwinNode): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: { message: this._translation.instant('node.deleteConfirmation') },
      panelClass: 'warning'
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === true) {
        this._service
          .deleteNodeById(node.id)
          .subscribe(() => this._router.navigate(['/node', node.parentId]));
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
