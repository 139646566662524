import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileCardIntl } from '@onyx/shell';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileCardTranslationsService
  extends ProfileCardIntl
  implements OnDestroy
{
  public languageSubscription: Subscription;

  constructor(private _service: TranslateService) {
    super();

    this.languageSubscription = _service.onLangChange.subscribe(() =>
      this._updateLabels()
    );

    this._updateLabels();
  }

  public ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  private _updateLabels(): void {
    this.logoutLabel = this._service.instant('onyx.shell.profileCard.logout');

    this.triggerChanges();
  }
}
