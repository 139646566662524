<ng-container *ngIf="softwareState">
  <table [dataSource]="softwareState.software" mat-table>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.software.name' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.name }}</td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.software.version' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.version }}</td>
    </ng-container>
    <ng-container matColumnDef="variant">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.software.variant' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.variant }}</td>
    </ng-container>
    <ng-container matColumnDef="vehicleType">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.software.vehicleType' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.vehicleType }}</td>
    </ng-container>
    <ng-container matColumnDef="relativePath">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.software.relativePath' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        <a href="{{ item.remoteLocation }}">{{ item.relativePath }}</a>
      </td>
    </ng-container>
    <tr
      *matHeaderRowDef="[
        'name',
        'version',
        'variant',
        'vehicleType',
        'relativePath'
      ]"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="
        let row;
        columns: ['name', 'version', 'variant', 'vehicleType', 'relativePath']
      "
      mat-row
    ></tr>
  </table>
</ng-container>
