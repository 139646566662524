import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { lastValueFrom } from 'rxjs';
import { GlobalSettingsIntl, ProfileCardIntl, ShellIntl } from '@onyx/shell';
import { ShellTranslationsService } from './onyx/shell-translations.service';
import { ProfileCardTranslationsService } from './onyx/profile-card-translations.service';
import { GlobalSettingsTranslationsService } from './onyx/global-settings-translations.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `/digital-twin/assets/i18n/`, '.json');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
      useDefaultLang: true
    })
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (service: TranslateService) => () => {
        const userSelectedLanguage = localStorage.getItem(
          'userSelectedLanguage'
        );
        if (userSelectedLanguage) {
          service.use(userSelectedLanguage);
        }
        return lastValueFrom(service.get('nav.home'));
      },
      deps: [TranslateService]
    },
    {
      provide: ShellIntl,
      useClass: ShellTranslationsService
    },
    {
      provide: ProfileCardIntl,
      useClass: ProfileCardTranslationsService
    },
    {
      provide: GlobalSettingsIntl,
      useClass: GlobalSettingsTranslationsService
    }
  ]
})
export class TranslationsModule {}
