import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettingsIntl } from '@onyx/shell';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsTranslationsService
  extends GlobalSettingsIntl
  implements OnDestroy
{
  public languageSubscription: Subscription;

  constructor(private _service: TranslateService) {
    super();

    this.languageSubscription = _service.onLangChange.subscribe(() =>
      this._updateLabels()
    );

    this._updateLabels();
  }

  public ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  private _updateLabels(): void {
    this.settingsLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.settingsLabel'
    );
    this.userSettingsLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.userSettingsLabel'
    );
    this.userPreferencesLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.userPreferencesLabel'
    );
    this.closeLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.closeLabel'
    );
    this.languageLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.languageLabel'
    );
    this.preferredLanguageLabel = this._service.instant(
      'onyx.shell.globalSettings.shell.preferredLanguageLabel'
    );
    this.screenSettings = {
      title: this._service.instant(
        'onyx.shell.globalSettings.screenSettings.title'
      ),
      light: this._service.instant(
        'onyx.shell.globalSettings.screenSettings.light'
      ),
      dark: this._service.instant(
        'onyx.shell.globalSettings.screenSettings.dark'
      )
    };

    this.triggerChanges();
  }
}
