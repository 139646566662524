<div class="container" *ngIf="item">
  <div class="vehiclename">
    <ui-title
      size="small"
      [state]="item.errorCode === '00000000' ? 'success' : 'error'"
      [iconTooltip]="item.errorDetails"
      [title]="item.vin"
      [subtitle]="item.sequenceNumber"
      [svgIcon]="true"
      [icon]="'icon_commissioning'"
    ></ui-title>
  </div>
  <div class="sidepanel-cards">
    <!-- error -->
    <ui-card
      *ngIf="item.errorDetails"
      [border]="true"
      [rounded]="true"
      [backgroundColor]="true"
    >
      <ui-title
        size="small"
        title="Error"
        state="error"
        [svgIcon]="true"
        [icon]="'icon_error'"
      ></ui-title>
      <p><strong>{{ 'requestOverview.sidePane.code' | translate }}:</strong> {{ item.errorCode }}</p>
      <p>
        <a *ngIf="item" aria-hidden="true" (click)="showDetails = !showDetails">
          <span *ngIf="showDetails">{{'requestOverview.sidePane.hideDetails' | translate}}</span>
          <span *ngIf="!showDetails">{{'requestOverview.sidePane.showDetails' | translate}}</span>
        </a>
      </p>
      <p class="error-card-details" *ngIf="showDetails">
        <strong>{{ 'requestOverview.sidePane.details' | translate }}:</strong>
        {{ item.errorDetails }}
      </p>
    </ui-card>

    <!-- exisiting digital twin -->
    <ui-card
      *ngIf="item?.matchingNode"
      [border]="true"
      [rounded]="true"
      [backgroundColor]="true"
    >
      <ui-title
        size="small"
        [title]="'requestOverview.sidePane.alreadyexists.title' | translate"
        state="warning"
        [svgIcon]="true"
        [icon]="'icon_warning'"
      ></ui-title>
      <p>
        {{'requestOverview.sidePane.alreadyexists.theDigitalTwin' | translate}}
        The digital twin
        <a [routerLink]="['/node', item.matchingNode?.id]">{{
          item.matchingNode?.vin
        }}</a>
        {{'requestOverview.sidePane.alreadyexists.hasBeenCreated' | translate}}
        has already been created at
        {{ item.matchingNode?.createdOn | date: 'medium' }}
      </p>
    </ui-card>

    <!-- download received data -->
    <ui-card [border]="true" [rounded]="true" [backgroundColor]="true">
      <ui-title
        size="small"
        [title]="'requestOverview.sidePane.receivedData.title' | translate"
        [subtitle]="'requestOverview.sidePane.receivedData.subtitle' | translate"
      ></ui-title>
      <p>
        <a
          class="link-with-icon"
          *ngIf="item"
          aria-hidden="true"
          (click)="downloadAllReceived.emit(item)"
          ><mat-icon svgIcon="icon_download"></mat-icon> {{'common.download' | translate}}</a
        >
      </p>
    </ui-card>

    <!-- download tdf -->
    <ui-card [border]="true" [rounded]="true" [backgroundColor]="true">
      <ui-title
        size="small"
        [title]="'requestOverview.sidePane.tdf.title' | translate"
        [subtitle]="'requestOverview.sidePane.tdf.subtitle' | translate"
      ></ui-title>
      <p>
        <a
          class="link-with-icon"
          *ngIf="item"
          aria-hidden="true"
          (click)="viewTDF.emit(item)"
          ><mat-icon svgIcon="icon_button-view"></mat-icon> {{'common.view' | translate}}</a
        >
      </p>
      <p>
        <a
          class="link-with-icon"
          *ngIf="item"
          aria-hidden="true"
          (click)="downloadTDF.emit(item)"
          ><mat-icon svgIcon="icon_download"></mat-icon> {{'common.download' | translate}}</a
        >
      </p>
    </ui-card>
  </div>
</div>
