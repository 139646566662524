<ui-shell-content-header [sticky]="true">
  <ui-shell-page-title [title]="'home.title' | translate">
  </ui-shell-page-title>
  <ng-container *ngIf="_nodes$ | async as nodes">
    <ui-card
      [border]="true"
      [rounded]="true"
      *ngFor="let rootNode of nodes.nodes"
      [routerLink]="['node', rootNode.id]"
    >
      <ui-card-header>
        <ui-title
          size="small"
          state="success"
          [title]="rootNode.name"
          [subtitle]="_nodeTypeEnum[rootNode.nodeType]"
          [svgIcon]="true"
          [icon]="
            rootNode.nodeType === _nodeTypeEnum.Group
              ? 'nav_management'
              : 'icon_commissioning'
          "
        ></ui-title>
      </ui-card-header>
    </ui-card>
  </ng-container>
</ui-shell-content-header>
