import { Component, Input } from '@angular/core';
import { TruckDefinitionOverview } from '../../models/truck-definition-overview';

@Component({
  selector: 'app-node-truckdefinition',
  templateUrl: './node-truckdefinition.component.html'
})
export class NodeTruckDefinitionComponent {
  @Input() public truckDefinition: TruckDefinitionOverview | null = null;
}
