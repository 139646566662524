<ng-container *ngIf="expectedParts">
  <table [dataSource]="expectedParts.parts" mat-table>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.expectedParts.name' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.expectedParts.type' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isDetectable">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.expectedParts.isDetectable' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.isDetectable }}
      </td>
    </ng-container>
    <ng-container matColumnDef="detectableAsType">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.expectedParts.detectableAsType' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.detectableAsType }}
      </td>
    </ng-container>
    <tr
      *matHeaderRowDef="['name', 'type', 'isDetectable', 'detectableAsType']"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="
        let row;
        columns: ['name', 'type', 'isDetectable', 'detectableAsType']
      "
      mat-row
    ></tr>
  </table>
</ng-container>
