import { Component, Input } from '@angular/core';
import { SoftwareState } from '../../models/software-state';

@Component({
  selector: 'app-node-software',
  templateUrl: './node-software.component.html'
})
export class NodeSoftwareComponent {
  @Input() public softwareState: SoftwareState | null = null;
}
