<form [formGroup]="_form" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <ui-title
      size="large"
      [title]="'node.showApplyBundleDialog.dialogtitle' | translate"
      state="success"
      [svgIcon]="true"
      icon="icon_AGV"
    ></ui-title>
  </div>
  <mat-dialog-content>
    @if (data.bundleVersions$ | async; as list) {
      @if (list && list.length > 0) {
        <mat-form-field>
          <mat-label>{{
            'node.showApplyBundleDialog.fieldName' | translate
          }}</mat-label>
          <mat-select formControlName="bundleVersion" required>
            <mat-option [value]="item" *ngFor="let item of list">{{
              item
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      } @else {
        {{ 'node.showApplyBundleDialog.noBundlesFound' | translate }}
        {{ data.vehicleType }}
      }
    } @else {
      {{ 'common.loading' | translate }} ...
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button type="button" (click)="cancel()">
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button type="submit" [disabled]="_form.invalid">
      {{ 'node.showApplyBundleDialog.apply' | translate }}
    </button>
  </mat-dialog-actions>
</form>
