import { Component, ViewEncapsulation } from '@angular/core';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { BehaviorSubject, map, mergeMap, Observable, startWith } from 'rxjs';
import { RequestOverviewItem } from '../models/request-overview-item';
import { GetNodesResponse } from '../models/get-nodes-response';
import { TableElement } from '@onyx/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request-overview',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './request-overview.component.html',
  styleUrl: './request-overview.component.scss'
})
export class RequestOverviewComponent {
  public requestOverview$: Observable<RequestOverviewItem[]>;
  public requestOverviewDetail$ =
    new BehaviorSubject<RequestOverviewItem | null>(null);
  public showSidePanel = false;
  public selection: RequestOverviewItem[] = [];

  public tableElements$: Observable<TableElement<RequestOverviewItem>[]>;

  constructor(
    public _service: DigitalTwinApiService,
    private _translateService: TranslateService
  ) {
    this.tableElements$ = _translateService.onLangChange.pipe(
      startWith(null),
      map(() => this._getTableStructure())
    );

    this.requestOverview$ = this._service.getTFDRequestOverview().pipe(
      mergeMap(list =>
        this._service
          .searchNode({
            vinFilter: list.map(i => i.vin)
          })
          .pipe(map(nodesResponse => this._combineLists(list, nodesResponse)))
      )
    );

    this.requestOverviewDetail$.subscribe(
      item => (this.showSidePanel = !!item)
    );
  }
  private _combineLists(
    list: RequestOverviewItem[],
    nodesResponse: GetNodesResponse
  ): RequestOverviewItem[] {
    nodesResponse.nodes.forEach(node => {
      const listItem = list.find(i => i.vin === node.vin);
      if (listItem) {
        listItem.matchingNode = node;
      }
    });
    return list;
  }

  private _getTableStructure(): TableElement<RequestOverviewItem>[] {
    return [
      {
        field: 'vin',
        header: this._translateService.instant('requestOverview.vin'),
        filterable: true
      },
      {
        field: 'factoryCode',
        header: this._translateService.instant('requestOverview.factory'),
        filterable: true
      },
      {
        field: 'errorCode',
        header: this._translateService.instant('requestOverview.error')
      },
      {
        field: 'matchingNode',
        header: this._translateService.instant('requestOverview.dtexists')
      },
      {
        field: 'createdOn',
        header: this._translateService.instant('requestOverview.createdon')
      }
    ];
  }

  public showDetail(selection: RequestOverviewItem[]): void {
    this.selection = selection;
    if (selection?.length) {
      this.requestOverviewDetail$.next(selection[0]);
    }
  }
}
