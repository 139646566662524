import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationConfig } from '@onyx/shell';

@Injectable({ providedIn: 'root' })
export class TranslatedNavigationService {
  private _baseConfig: NavigationConfig = {
    navigationItems: [
      {
        key: 'home',
        label: 'nav.home',
        customIcon: false,
        link: [''],
        iconName: 'home',
        exactMatch: true
      },
      {
        key: 'twinrequestoverview',
        label: 'nav.requestOverview',
        customIcon: true,
        link: ['twinrequestoverview'],
        iconName: 'nav_agv-digital-twin',
        exactMatch: true
      }
    ],
    extendedNavigationItems: []
  };

  constructor(private _service: TranslateService) {}

  public getTranslatedNavigationConfig(): NavigationConfig {
    const clone: NavigationConfig = JSON.parse(
      JSON.stringify(this._baseConfig)
    );
    clone.navigationItems.forEach(element => {
      if (element.label) {
        element.label = this._service.instant(element.label);
      }
    });
    return clone;
  }
}
