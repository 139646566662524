import { Component, Input } from '@angular/core';
import { PartsOverviewExpected } from '../../models/parts-overview-expected';

@Component({
  selector: 'app-node-expected-parts',
  templateUrl: './node-expected-parts.component.html'
})
export class NodeExpectedPartsComponent {
  @Input() public expectedParts: PartsOverviewExpected | null = null;
}
