import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NodeComponent } from './node/node.component';
import { RequestOverviewComponent } from './request-overview/request-overview.component';
import { AddNodeComponent } from './node/add/add-node.component';
import { EditSoftwareComponent } from './node/edit-software/edit-software.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent
        //canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'node/:id',
        component: NodeComponent
        //canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'node/:id/add',
        component: AddNodeComponent
        //canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'node/:id/edit-software',
        component: EditSoftwareComponent
        //canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'twinrequestoverview',
        component: RequestOverviewComponent
        //canActivate: [digitalTwinRoleGuard('admin')]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
