import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule } from '@angular/router';
import {
  CoreModule,
  FilterModule,
  GenericTableModule,
  IconsModule
} from '@onyx/core';
import { NAVIGATION_CONFIG, ShellModule } from '@onyx/shell';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RequestOverviewComponent } from './request-overview/request-overview.component';
import { RequestOverviewSidepaneComponent } from './request-overview/sidepane/request-overview-sidepane.component';
import { NodeComponent } from './node/node.component';
import { TranslationsModule } from './shared/modules/translations/translations.module';
import { TranslatedNavigationService } from './shared/modules/translations/onyx/translated-navigation.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { NodeBlobsComponent } from './node/blobs/node-blobs.component';
import { NodeExpectedPartsComponent } from './node/expectedparts/node-expected-parts.component';
import { NodeReportedPartsComponent } from './node/reportedparts/node-reported-parts.component';
import { NodeSoftwareComponent } from './node/software/node-software.component';
import { NodeTruckDefinitionComponent } from './node/truckdefinition/node-truckdefinition.component';
import { NodeHistoryComponent } from './node/history/node-history.component';
import { AddNodeComponent } from './node/add/add-node.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApplyBundleDialogComponent } from './node/apply-bundle/apply-bundle-dialog.component';
import { NodeDiffComponent } from './node/node-diff/node-diff.component';
import { RevertDialogComponent } from './node/revert-dialog/revert-dialog.component';
import { ShowDiffDialogComponent } from './node/show-diff-dialog/show-diff-dialog.component';
import { BundleDeviationsDialogComponent } from './node/bundle-deviations-dialog/bundle-deviations-dialog.component';
import { EditSoftwareComponent } from './node/edit-software/edit-software.component';
import { EditSoftwareEditDialogComponent } from './node/edit-software/edit-dialog/edit-software-edit-dialog.component';
import { EditSoftwareAddDialogComponent } from './node/edit-software/add-dialog/edit-software-add-dialog.component';
import { CreateByTdfComponent } from './request-overview/create-by-tdf/create-by-tdf.component';
import { DragAndDropZoneComponent } from './shared/components/drag-and-drop-zone/drag-and-drop-zone.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    AddNodeComponent,
    AppComponent,
    ApplyBundleDialogComponent,
    BundleDeviationsDialogComponent,
    ConfirmationDialogComponent,
    CreateByTdfComponent,
    DragAndDropZoneComponent,
    EditSoftwareComponent,
    EditSoftwareAddDialogComponent,
    EditSoftwareEditDialogComponent,
    HomeComponent,
    NodeComponent,
    NodeBlobsComponent,
    NodeDiffComponent,
    NodeExpectedPartsComponent,
    NodeHistoryComponent,
    NodeReportedPartsComponent,
    NodeSoftwareComponent,
    NodeTruckDefinitionComponent,
    RequestOverviewComponent,
    RequestOverviewSidepaneComponent,
    RevertDialogComponent,
    ShowDiffDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShellModule.forRoot(
      { navigationItems: [], extendedNavigationItems: [] },
      {
        defaultTitle: 'Digital Twin Manager'
      }
    ), // not using forRoot because we are manually injecting NAVIGATION_CONFIG
    RouterModule.forRoot([]),
    FormsModule,
    ReactiveFormsModule,
    TranslationsModule,
    MatIcon,
    MatButton,
    CoreModule,
    IconsModule,
    MatSlideToggle,
    GenericTableModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FilterModule,
    MatMenuModule
  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    {
      provide: NAVIGATION_CONFIG,
      useFactory: (service: TranslatedNavigationService) =>
        service.getTranslatedNavigationConfig(),
      deps: [TranslatedNavigationService]
    }
  ]
})
export class AppModule {}
