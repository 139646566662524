<ng-container *ngIf="reportedParts">
  <table [dataSource]="reportedParts.parts" mat-table>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.name' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="manufacturer">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.manufacturer' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.manufacturer }}
      </td>
    </ng-container>
    <ng-container matColumnDef="serial">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.serial' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.serial }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.type' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.state' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.state }}
      </td>
    </ng-container>
    <ng-container matColumnDef="partNumber">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.reportedParts.partNumber' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.partNumber }}
      </td>
    </ng-container>
    <tr
      *matHeaderRowDef="[
        'name',
        'manufacturer',
        'serial',
        'type',
        'state',
        'partNumber'
      ]"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="
        let row;
        columns: [
          'name',
          'manufacturer',
          'serial',
          'type',
          'state',
          'partNumber'
        ]
      "
      mat-row
    ></tr>
  </table>
</ng-container>
