<div mat-dialog-title>
  <ui-title
    size="large"
    [title]="'confirm.title' | translate"
    state="warning"
    [svgIcon]="true"
    [iconOnly]="true"
    icon="icon_warning"
  ></ui-title>
</div>
<mat-dialog-content>
  {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">
    {{ 'confirm.cancel' | translate }}
  </button>
  <button mat-flat-button (click)="confirm()">
    {{ 'confirm.ok' | translate }}
  </button>
</mat-dialog-actions>