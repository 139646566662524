import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { DigitalTwinNodeTypeEnum } from '../models/digital-twin-node-type.enum';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { Observable } from 'rxjs';
import { GetNodesResponse } from '../models/get-nodes-response';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  public environment = environment;
  public _nodes$: Observable<GetNodesResponse>;
  public _nodeTypeEnum = DigitalTwinNodeTypeEnum;

  constructor(private _service: DigitalTwinApiService) {
    this._nodes$ = this._service.searchNode({
      page: 1,
      size: -1,
      parentId: -1
    });
  }
}
