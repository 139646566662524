<ng-container *ngIf="crc">
  <table [dataSource]="crc.crc" mat-table>
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.crcCodes.name' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.crcCodes.value' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.value }}
      </td>
    </ng-container>
    <tr *matHeaderRowDef="['name', 'value']" mat-header-row></tr>
    <tr *matRowDef="let row; columns: ['name', 'value']" mat-row></tr>
  </table>
</ng-container>
