import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NodeComponent } from './node/node.component';
import { RequestOverviewComponent } from './request-overview/request-overview.component';
import { AddNodeComponent } from './node/add/add-node.component';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { digitalTwinRoleGuard } from './guards/digital-twin-role-guard.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AutoLoginAllRoutesGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'node/:id',
        component: NodeComponent,
        canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'node/:id/add',
        component: AddNodeComponent,
        canActivate: [digitalTwinRoleGuard('admin')]
      },
      {
        path: 'twinrequestoverview',
        component: RequestOverviewComponent,
        canActivate: [digitalTwinRoleGuard('admin')]
      }
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
